<script setup lang="ts">
defineProps<Props>()

const { myLayer } = useAppConfig()

interface Props {
  model: {
    id: number
    name: string
    primary_image_url: string
    jato_brand: {
      name: string
      slug: string
    }
    slug: string
  }
  fromPrice: number
}
</script>

<template>
  <ULandingCard
    :key="model.id"
    :to="myLayer.name === 'hellocar'
      ? `/nye-biler/${model.jato_brand.slug}/${model.slug}`
      : `/nye-varebiler/${model.jato_brand.slug}/${model.slug}`"
    color="primary"
    class="transition-all hover:scale-[101%] hover:shadow-xl duration-300 cursor-pointer"
    :ui="{
      wrapper: 'relative group isolate rounded-3xl background-gradient ring-1 ring-gray-200 dark:ring-gray-800 before:hidden before:lg:block before:absolute before:-inset-[2px] before:h-[calc(100%+4px)] before:w-[calc(100%+4px)] before:z-[-1] before:rounded-[25px] flex-1 flex flex-col shadow',
      to: 'hover:ring-primary-500 dark:hover:ring-primary-400 transition-shadow duration-200',
      base: 'flex-1 flex flex-col overflow-hidden',
      container: '',
      body: {
        padding: 'p-0 sm:p-0 flex sm:block',
        base: '',
        background: 'bg-white',
      },
      background: 'bg-white dark:bg-gray-900 hover:bg-opacity-90 dark:hover:bg-opacity-90 transition-[background-opacity]',
      ring: '',
      rounded: 'rounded-3xl',
      shadow: '',
      icon: {
        wrapper: 'mb-2 pointer-events-none',
        base: 'w-8 h-8 flex-shrink-0 text-gray-900 dark:text-white',
      },
      title: 'text-gray-900 dark:text-white text-base font-bold truncate',
      description: 'text-[15px] text-gray-500 dark:text-gray-400 mt-1',
    }"
  >
    <div class="w-1/2 sm:w-full">
      <img
        v-if="model.primary_image_url"
        :src="useCloudinary(model.primary_image_url, {
          quality: 'auto',
          format: 'webp',
          resize: {
            height: 500,
            aspectRatio: '4:3',
            type: 'pad',
          },
        })"
        class="w-full h-full object-contain sm:px-4 p-1.5 bg-primary-50 border-b border-secondary-200"
        :alt="`Billede af en ${model.jato_brand.name} ${model.name} ${new Date().getFullYear()} varebil`"
      >
    </div>
    <div class="flex flex-col justify-between h-full px-4 py-4 sm:justify-start sm:h-auto w-1/2 sm:w-full">
      <div>
        <p class="text-sm font-regola-book text-gray-600">
          {{ model.jato_brand.name }}
        </p>
        <p class="text-base sm:text-lg font-regola-medium font-normal text-black">
          {{ model.name }}
        </p>
      </div>

      <UDivider class="my-2" />

      <div class="text-base md:text-lg flex items-center justify-between font-regola-medium font-normal text-black">
        <p>
          {{ useFormatPrice(fromPrice) }}
        </p>
        <p class="text-gray-600 text-sm">
          pr.
          <span class="sm:hidden">mnd</span>
          <span class="hidden sm:inline">måned</span>
        </p>
      </div>
    </div>
  </ULandingCard>
</template>
